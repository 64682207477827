import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { useStaticQuery, graphql } from "gatsby";

// React
import { AdvancedImage, responsive, lazyload } from "@cloudinary/react";
import { Cloudinary } from "@cloudinary/url-gen";

// SVGs
import AritaeLogo from "../images/logo/logo.inline.svg";
import AritaeLogoLetters from "../images/logo/logo-letters.inline.svg";

// Heroicons
import { ArrowNarrowDownIcon } from "@heroicons/react/outline";

// Shared Components
import LoadingButton from "../components/shared/loading-button/LoadingButton";
import Footer from "../components/shared/footer/Footer";
import Header from "../components/shared/header/Header";
import Seo from "../components/seo";
import TextModal from "../components/shared/modal/Modal";

// Services
import { contactSubmission } from "../services/contactAPI";

export default function Academy() {
  // Create a Cloudinary instance and set your cloud name.
  const cld = new Cloudinary({
    cloud: {
      cloudName: "kirkwork",
    },
  });
  // define our cloudinary images
  const ResuableImage = (imageURL) => cld.image(imageURL).quality("auto");

  const HeaderImg = cld
    .image("v1649275047/Aritae/Testimonials/Header_xglf7j.jpg")
    .quality("auto");
  const HeaderMobile = cld
    .image("v1649275092/Aritae/Testimonials/header-small_b2qtev.jpg")
    .quality("auto");
  const FooterImg = cld
    .image("v1649335110/Aritae/Testimonials/baseball_bag_vf1nie.jpg")
    .quality("auto");
  const FooterMobile = cld
    .image("v1649335110/Aritae/Testimonials/baseball_bag_small_r0javq.jpg")
    .quality("auto");

  const [isOpen, setIsOpen] = useState(false);
  const [icon, setIcon] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [largeScreen, setLargeScreen] = useState(true);

  function checkScreenSize() {
    if (window.innerWidth <= 768) {
      setLargeScreen(false);
    } else {
      setLargeScreen(true);
    }
  }

  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  // Modal
  function toggleModalOpen() {
    setIsOpen(!isOpen);
  }

  //email sent from contact form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);

    const email = event.target.email.value;

    let postBody = JSON.stringify({
      Email: email,
      PageName: "Testimonials",
    });

    contactSubmission(postBody).then(async (response) => {
      const data = await response;
      if (data.dbSuccess === true && data.endUserEmailSuccess === true) {
        // modal success
        setIsOpen(true);
        setIcon("success");
        setDescription(
          "Thanks for your interest in ARITAE! Please check your inbox for updates."
        );
        setLoading(false);
        event.target.reset();
      } else {
        // modal error
        setIsOpen(true);
        setIcon("failure");
        setDescription("Something went wrong. Please try again.");
        setLoading(false);
        event.target.reset();
      }
    });
  };

  // This query will get all of your posts
  const posts = useStaticQuery(graphql`
    query Testimonials {
      allTestimonial {
        edges {
          node {
            id
            mdx
            title
            description
            url
            image_url
          }
        }
      }
    }
  `);

  return (
    <div id="root">
      <Header />
      <Seo
        title="Testimonials"
        description="All about the success that ARITAE brings."
      />
      <TextModal
        isOpen={isOpen}
        close={toggleModalOpen}
        icon={icon}
        description={description}
      />

      <div className="relative bg-gray-100">
        <div className="mx-auto">
          {/* START HERO SECTION */}
          <div className="h-screen relative">
            <div className="absolute inset-0">
              <div className="absolute flex-1 top-0 inset-0">
                <div className={"absolute inset-0 opacity-50 bg-stone-500"} />

                <AdvancedImage
                  plugins={[responsive({ steps: 200 })]}
                  cldImg={largeScreen ? HeaderImg : HeaderMobile}
                  alt="coach showing young woman a play on a clipboard in a green field"
                  className="h-full w-full object-cover"
                />
              </div>
            </div>
            <div className="relative">
              <div className="flex flex-col h-screen items-center justify-center">
                <h1 className="text-center text-4xl font-extrabold sm:text-5xl lg:text-6xl">
                  <span className="block text-white text-2xl pb-6">
                    TESTIMONIALS
                  </span>
                </h1>
                <div
                  className={"flex flex-col items-center justify-center my-12"}
                >
                  <AritaeLogo
                    fill={"white"}
                    className={"h-32 w-auto mx-auto pb-2"}
                  />
                  <AritaeLogoLetters
                    fill={"white"}
                    className={"h-auto w-48 mx-auto"}
                  />
                </div>
                <p className={"text-white text-center font-bold text-4xl mt-4"}>
                  HEAR THE SUCCESS
                </p>
                <div className="hidden lg:flex mt-10 max-w-sm mx-auto justify-center">
                  <ArrowNarrowDownIcon className={"h-7 text-white"} />
                </div>
              </div>
            </div>
          </div>
          {/* END HERO SECTION */}

          {/* Modify the our coaches section to display the blog posts. */}

          {/* START OUR TESTIMONIALS */}
          <div className="relative mx-16">
            <div className={"relative py-24 max-w-7xl mx-auto"}>
              <div className={"pb-12"}>
                <div className={"flex items-center"}>
                  <h1
                    className={
                      "mx-auto text-slate-600 max-w-xl text-center text-4xl"
                    }
                  >
                    OUR <span className="font-extrabold">TESTIMONIALS</span>
                  </h1>
                </div>
              </div>
              <ul className="grid mx-auto grid-cols-1 gap-y-8 sm:grid-cols-3 gap-x-3 max-w-sm sm:max-w-none">
                {posts.allTestimonial.edges.map((edge) => {
                  const { title, description, image_url, url, id } = edge.node;
                  return (
                    <li key={id} className="relative">
                      <Link to={`/testimonials/${url}`}>
                        <div className="relative group block w-full h-full aspect-w-1 aspect-h-1 overflow-hidden">
                          <figure className="">
                            <AdvancedImage
                              plugins={[responsive({ steps: 200 })]}
                              cldImg={ResuableImage(image_url)}
                              alt="Testimonial Post"
                              className="h-full w-full object-cover"
                            />

                            <div
                              className={
                                "absolute inset-0 flex bg-overlay opacity-50 hover:opacity-30 transition-opacity duration-200"
                              }
                            />
                            <div className="absolute left-2 bottom-12">
                              <figcaption
                                className={
                                  " text-white text-3xl font-extrabold "
                                }
                              >
                                {title}
                              </figcaption>
                              <figcaption
                                className={" text-white text-xl font-extrabold"}
                              >
                                {description}
                              </figcaption>
                            </div>
                          </figure>
                        </div>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          {/* END OUR TESTIMONIALS */}

          {/* START Sign Up Form */}
          <div className={"flex h-screen justify-center items-center mt-24"}>
            <div className="absolute z-10 flex h-screen w-screen overflow-hidden">
              <div className={"absolute inset-0 opacity-75 bg-stone-500"} />
              <AdvancedImage
                plugins={[lazyload()]}
                cldImg={largeScreen ? FooterImg : FooterMobile}
                alt="boy playing piano and girl playing saxophone on stage in front of a large audience "
                className="w-full h-full object-cover"
              />
            </div>

            <div className="relative z-20 mx-auto max-w-3xl text-center md:px-5">
              <div className="py-10">
                <h4 className="text-4xl font-extrabold text-white">
                  ARE YOU READY TO UNLEASH YOUR POTENTIAL?
                </h4>
              </div>

              <form
                className="space-y-6 row-auto py-5 p-4  max-w-3xl xs:m-12 md:m-0"
                action="#"
                method="POST"
                onSubmit={handleSubmit}
              >
                <div className="mx-auto">
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4 items-center justify-between">
                    <div className="mt-1 md:col-span-2">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        placeholder="Email"
                        required
                        className="text-center md:text-left w-full px-5 py-3 text-gray-500 border-transparent placeholder-gray-500 bg-gray-100 font-sm md:font-md"
                      />
                    </div>

                    <div className="mt-1">
                      {loading ? (
                        <LoadingButton type={"email"} />
                      ) : (
                        <button
                          type="submit"
                          className="w-full flex justify-center px-5 py-3 border border-transparent rounded shadow-sm text-sm text-white font-bold bg-black hover:bg-gray-800"
                        >
                          SIGN UP
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          {/* END Sign Up Form */}
        </div>
      </div>
      <Footer />
    </div>
  );
}
